import { handler } from '@/http-interceptor'
import axios from "axios";
handler()

// 获取城市列表
export function citylist() {
  return axios.get("/mall/city_list", {});
}

// 获取注册抽奖二维码
export function downloadCode() {
  return axios.post("/mall/web/ma/store_activity/download/downloadCode", {});
}
