import "core-js/modules/es.array.includes.js";
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import logo from '@/components/logo';
import usebase from '@/hooks/usebase';
import usecountdown from '@/hooks/usecountdown';
import { citylist, downloadCode } from '@/api/index';
import { getVerify, userVerify, userRegister, wechatOpenBinding } from '@/api/user';
export default {
  name: '',
  props: [],
  components: {
    logo
  },

  setup() {
    const router = useRouter();
    const {
      isPhone,
      isEmail,
      isMessage,
      getUrlParam
    } = usebase();
    const {
      counttetx,
      isdisabled,
      timer,
      countdown
    } = usecountdown();
    const state = reactive({
      nextnum: 1,
      cityoptions: [],
      optionProps: {
        expandTrigger: 'hover',
        value: 'n',
        label: 'n',
        children: 'c'
      },
      codeform: {
        phone: '',
        type: 'register',
        captcha: ''
      },
      userform: {
        phone: '',
        password: '',
        passwordor: '',
        realName: '',
        orgName: '',
        belongAreaor: [],
        belongArea: '',
        address: '',
        email: ''
      },
      lookchecked: true,
      type: '',
      luckdrawImg: ''
    });
    const refState = toRefs(state);

    const userXyi = () => {
      router.push('/useragreement');
    };

    const userYsi = () => {
      router.push('/privacypolicy');
    };

    const goLogin = () => {
      setTimeout(() => {
        router.push('/login');
      }, 1000);
    }; //  获取验证码


    const getCode = () => {
      if (!state.codeform.phone) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!isPhone(state.codeform.phone)) {
        isMessage('warning', '请输入合法手机号');
        return;
      }

      getVerify(state.codeform).then(res => {
        let {
          status,
          msg
        } = res;

        if (status == 200) {
          isMessage('success', msg || '验证码发送成功');
          countdown();
          return;
        } else {
          isMessage('error', msg || '验证码发送失败');
        }
      });
    }; // 校验用户是否存在


    const handlerLogin = () => {
      if (!state.codeform.phone) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!isPhone(state.codeform.phone)) {
        isMessage('warning', '请输入合法手机号');
        return;
      }

      if (!state.codeform.captcha) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!state.lookchecked) {
        isMessage('warning', '请先阅读并同意《用户协议》《隐私政策》');
        return;
      }

      let data = {
        mobile: state.codeform.phone,
        captcha: state.codeform.captcha
      };
      userVerify(data).then(res => {
        let {
          status,
          msg,
          data
        } = res;

        if (status == 200) {
          let {
            exist
          } = data;

          if (exist == 1) {
            isMessage('success', '用户已存在，请前往登录');
            return;
          } else {
            state.nextnum = 2;
          }
        } else {
          isMessage('error', msg || '操作失败');
          return;
        }
      });
    };

    const handleChange = e => {
      console.log(e);
    }; // 注册


    const handlerRegister = () => {
      let {
        password,
        passwordor,
        realName,
        orgName,
        belongAreaor,
        address,
        email
      } = state.userform;

      if (!state.codeform.phone) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!password || !passwordor) {
        isMessage('warning', '请输入密码');
        return;
      }

      if (password.length < 6 || passwordor.length < 6) {
        isMessage('warning', '密码长度不能低于6位');
        return;
      }

      if (password !== passwordor) {
        isMessage('warning', '两次输入的密码不一致');
        return;
      }

      if (!realName) {
        isMessage('warning', '请输入用户姓名');
        return;
      }

      if (realName.length < 2) {
        isMessage('warning', '用户姓名长度不能低于2位');
        return;
      }

      if (!orgName) {
        isMessage('warning', '单位学校+空格+导师名称/公司名称');
        return;
      }

      if (!email) {
        isMessage('warning', '请输入邮箱地址');
        return;
      }

      if (!isEmail(email)) {
        isMessage('warning', '请输入合法邮箱');
        return;
      }

      if (!belongAreaor) {
        isMessage('warning', '请输入选择所在地区');
        return;
      }

      if (!address) {
        isMessage('warning', '请输入详细地址');
        return;
      }

      if (!state.lookchecked) {
        isMessage('warning', '请先阅读并同意《用户协议》《隐私政策》');
        return;
      }

      let wechatUser = localStorage.getItem('wxuser') ? JSON.parse(localStorage.getItem('wxuser')) : '';
      state.userform.belongArea = state.userform.belongAreaor.join(' ');
      state.userform.phone = state.codeform.phone;
      state.userform.wechatUser = wechatUser;
      userRegister(state.userform).then(res => {
        let {
          status,
          msg
        } = res;

        if (status == 200) {
          // isMessage('success', msg || '注册成功，请前往登录')
          // goLogin()
          getDownloadCode(msg);
        } else {
          isMessage('error', msg || '注册失败');
          return;
        }
      });
    };

    const getDownloadCode = async msg => {
      try {
        let {
          data
        } = await downloadCode();

        if (!data) {
          isMessage('success', msg || '注册成功，请前往登录');
          goLogin();
        } else {
          state.luckdrawImg = data;
          state.nextnum = 3;
        }
      } catch (err) {
        console.log(err);
      }
    }; // 绑定手机号


    const bindMobile = () => {
      let mobile = state.codeform.phone;
      let captcha = state.codeform.captcha;
      let wechatUser = localStorage.getItem('wxuser') ? JSON.parse(localStorage.getItem('wxuser')) : '';
      let idata = {
        mobile,
        captcha,
        wechatUser
      };
      wechatOpenBinding(idata).then(res => {
        console.log(res);
        let {
          status,
          data
        } = res;

        if (status == 200) {
          if (data.token) {
            toPath(data.token);
          }
        } else {
          state.nextnum = 2;
        }
      });
    }; // 登录成功路由处理


    const toPath = token => {
      let redirect = localStorage.getItem('redirect');
      localStorage.clear();

      if (!redirect) {
        window.location.href = 'http://www.ysbuy.com/';
      } else {
        if (redirect.includes('?')) {
          window.location.href = `${redirect}&code=${token}`;
        } else {
          window.location.href = `${redirect}?code=${token}`;
        }
      }
    };

    onBeforeMount(() => {
      localStorage.clear(); // 省市区联动

      citylist().then(res => {
        if (res.status == 200) {
          let {
            data
          } = res;
          state.cityoptions = data;
        }
      });

      if (getUrlParam('type')) {
        state.type = getUrlParam('type');
      } else {
        state.type = '';
      }
    }); // 重定向url

    const getRedirect = () => {
      let redirect = router.currentRoute.value.href.split('redirect=')[1] || localStorage.getItem('redirect') || '';
      localStorage.setItem('redirect', redirect);
    };

    onMounted(() => {
      getRedirect();
    });
    return { ...refState,
      userXyi,
      userYsi,
      getCode,
      handlerLogin,
      handleChange,
      handlerRegister,
      goLogin,
      bindMobile,
      counttetx,
      isdisabled,
      timer
    };
  }

};